const white = "#fff";
const gray = {
  200: "#F9FDFF",
  300: "#F1FAFF",
  400: "#F1FAFF",
  500: "#B1E6FF",
  600: "#003147",
  700: "#003147",
  800: "#00151f",
  900: "#00151f",
};
const indigo = {
  100: "#B1E6FF",
  300: "#B1E6FF",
  500: "#00B0FF",
  600: "#006A99",
  800: "#002333",
};
const purple = {
  100: "#fffbcc",
  300: "#fff266",
  500: "#ffea00",
  600: "#ffea00",
  800: "#ffea00",
};
const green = {
  100: "#f0fff4",
  500: "#48bb78",
};
const red = {
  100: "#fff5f5",
  500: "#f56565",
};

//Primary
const alpha = {
  alphaLighter: indigo[100],
  alphaLight: indigo[300],
  alpha: indigo[500],
  alphaDark: indigo[600],
  alphaDarker: indigo[800],
};

// Secondary
const beta = {
  betaLighter: purple[100],
  betaLight: purple[300],
  beta: purple[500],
  betaDark: purple[600],
  betaDarker: purple[800],
};

// Reserved
const gamma = {};

// Reserved
const psi = {};

// Neutral
const omega = {
  omegaLighter: gray[200],
  omegaLight: gray[300],
  omega: gray[500],
  omegaDark: gray[600],
  omegaDarker: gray[800],
};

export default {
  text: gray[600],
  article: gray[700],
  heading: gray[800],

  ...alpha,
  ...beta,
  ...gamma,
  ...psi,
  ...omega,

  successLight: green[100],
  success: green[500],
  errorLight: red[100],
  error: red[500],

  white: white,
  background: `#f8f8f8`,
  contentBg: white,
  headerBg: white,
  footerBg: white,

  mute: gray[300],
  highlight: gray[200],

  modes: {
    dark: {
      // Reverse alpha
      alphaLighter: alpha.alphaDarker,
      alphaLight: alpha.alphaDark,
      alphaDark: alpha.alphaLight,
      alphaDarker: alpha.alphaLighter,

      // Reverse beta
      betaLighter: beta.betaDarker,
      betaLight: beta.betaDark,
      betaDark: beta.betaLight,
      betaDarker: beta.betaLighter,

      // Reverse omega
      omegaLighter: omega.omegaDarker,
      omegaLight: omega.omegaDark,
      omegaDark: omega.omegaLight,
      omegaDarker: omega.omegaLighter,

      text: gray[400],
      article: gray[300],
      heading: gray[200],

      background: gray[900],
      contentBg: gray[800],
      headerBg: gray[800],
      footerBg: gray[800],

      mute: gray[600],
    },
  },
};
